.card-profile {
  $border-color: #E1E1E1;
  $font-size: .9rem;

  padding: .8rem;

  &_header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_avatar {
      $size: 1.875rem;

      width: $size;
      height: $size;
      border-radius: 50%;
      border: 1px solid $border-color;
    }

    &_name {
      padding-left: 0.6rem;
      font-size: .9rem;
      max-width: 200px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  .divider {
    height: 1px;
    background-color: $light;
    width: 100%;
    margin: $size-7 0;
  }

  &_body {
    &_content {
      display: flex;

      font-size: .85rem;

      &:not(:last-child) {
        margin-bottom: .4rem;
      }

      &_name {
        display: flex;
        align-items: center;
        width: max-content;
        margin-right: .5rem;

        font-weight: 700;
        font-size: .83rem;
      }
    }
  }
}

.avatar-external {
  min-width: 22px;
  max-width: 22px;
  margin-bottom: -7px !important;
  border-radius: 50%;
}

img {
  margin-bottom: -4px !important;
}

.user-wrapper {
  width: max-content;
  display: inline-block;
}